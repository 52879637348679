import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          <a
    	    className="App-link"
    	    href="https://trackzero.com"
    	    target="_blank"
    	    rel="noopener noreferrer"
       	  >
    	    trackzero.com
    	  </a>
    	  {/* Edit src/App.js and save to reload. */}
        </p>
    
      </header>
    </div>
  );
}

export default App;
